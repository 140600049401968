<template>
  <div class="wrap">
    <div class="content bg">
      <div class="news">
        <h2>聚焦武汉生物医疗行业发展 “2020武汉互联网突围”大咖直播访谈第六期圆满举行<span>2020-07-13</span></h2>
        <div class="new-con">
          <p>7月10日上午，2020武汉互联网突围大咖直播访谈第六期“打破常规 创造奇迹”准时开讲，武汉软件新城发展有限公司总经理梁勇，武汉必盈生物科技有限公司总经理刘朋，英特沃德（武汉）医疗科技发展有限公司创始人邢晓幕，武汉市人社局职业能力建设处潘炳武副处长、武汉市人社局人力资源市场办公室主任旷卫烽及光谷现代服务业园建设管理办公室副主任喻春波齐聚直播间，共同探讨如何用技术力量打破固有格局，为医院、患者提供现代化、智能化医疗服务，并围绕企业复工复产、人才招聘及企业周边基础设施完善等问题作出解答。</p>
          <p><img src="../../assets/images/news/new3-img1.jpg" alt=""></p>
          <p>作为武汉首批复工的园区，武汉软件新城在新冠肺炎疫情影响背景下帮助企业恢复发展、与企业共度难关。从疫情爆发至今，经过从上至下的统一部署、共同抗疫，园区无一感染病例。针对企业复工复产需求，在实施严格防控的同时，武汉软件新城制定了详细的《企业复工复产防疫指南》，对企业申报复工、防疫物资准备、物业消杀、员工就餐、员工返工信息登记、出行安排等需求制定了详细的制度，指导企业安全、高效复工。</p>
          <p><img src="../../assets/images/news/new3-img2.jpg" alt=""></p>
          <p class="tac">武汉软件新城发展有限公司总经理 梁勇</p>
          <p>武汉生物医疗企业在此次武汉疫情防控中作出了重要贡献，除了分享企业复工复产经验外，刘朋与邢晓幕还就“医疗行业转型时期的危与机”这一话题作出了探讨，表示这次疫情是人类历史上罕见的公共卫生突发事件，全球范围内的医疗体系都在经受疫情的考验和检验，现代化的新型医疗体系建设迫在眉睫。</p>
          <p><img src="../../assets/images/news/new3-img3.jpg" alt=""></p>
          <p class="tac">武汉必盈生物科技有限公司 总经理 刘朋</p>
          <p><img src="../../assets/images/news/new3-img4.jpg" alt=""></p>
          <p class="tac">英特沃德（武汉）医疗科技发展有限公司创始人 邢晓幕</p>
          <p>疫情对企业的发展特别是人才的引进带来了一系列的影响，武汉市如何破解“招工难”“就业难”的结构性矛盾？武汉市人社局人力资源市场办公室主任旷卫烽现场解答：“一是安排就业服务专员摸清企业用工需求，制定就业帮扶计划，一对一的开展就业指导。二是通过“湖北公共招聘网·武汉”网站（http://www.hbggzp.cn/）发布岗位信息，根据企业需求开辟招聘专区。三是组织开展“情系汉企”招聘直通车活动，组织企业招聘专员赴湖北周边人力资源丰富的地区开展招聘；同时组织部分院校与企业进行对接，输送高技能人才。”</p>
          <p><img src="../../assets/images/news/new3-img5.jpg" alt=""></p>
          <p class="tac">武汉市人社局人力资源市场办公室主任 旷卫烽</p>
          <p>武汉市人社局职业能力建设处潘炳武副处长介绍，近期武汉相继公布了“黄金十条”培训补贴政策，支持在汉企业以训稳岗、以训扩岗；在补贴政策执行方面，采取市区联动的办法，力求补贴申报更加便捷化、易操作。具体政策和申报指南可登陆武汉市人社局官网进行查询。</p>
          <p><img src="../../assets/images/news/new3-img6.jpg" alt=""></p>
          <p class="tac">武汉市人社局职业能力建设处副处长 潘炳武</p>
          <p>光谷现代服务业园建设管理办公室副主任喻春波就疫情影响之下如何帮扶企业作出了回应。园区组织开展走访调研企业活动，在稳步推进企业复工复产的同时，积极落实各项帮扶政策，急企业之所急，帮企业之所想，助力企业渡过难关。</p>
          <p><img src="../../assets/images/news/new3-img7.jpg" alt=""></p>
          <p class="tac">光谷现代服务业园建设管理办公室副主任 喻春波</p>
        </div>
        <div class="source"><a href="http://3g.k.sohu.com/t/n465850910" target="_blank">阅读原文</a></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'News'
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 444px;
  margin-top: 76px;
  background: url("../../assets/images/banner4.png") no-repeat center;
  text-align: center;
  overflow: hidden;
  .text {
    display: inline-block;
    margin: 117px auto 0;
  }
  .icon {
    width: 105px;
    height: 105px;
    margin: 0 auto;
    background: url("../../assets/images/banner4-icon.png") no-repeat center;
  }
  .h2 {
    font-size: 30px;
    color: #fff;
    margin-top: 62px;
  }
}
.bg {
  background: #fafafa;
}
.content {
  position: relative;
  padding: 60px 0;
  margin-top: 76px;
  .news {
    width: 1200px;
    margin: 0px auto;
    overflow: hidden;
    h2 {
      text-align: center;
      margin-bottom: 30px;
      span {
        display: block;
        margin: 10px auto 0;
        font-size: 14px;
      }
    }
    .new-con {
      width: 80%;
      margin: 0 auto;
      p {
        margin-bottom: 30px;
        text-indent: 2em;
        line-height: 2.2;
        img {
          display: block;
          margin: 0 auto;
        }
        &.tac{
          text-align: center;
          margin-top: -30px;
        }
      }
    }
    .source a {
      font-size: 12px;
      color: #434343;
    }
  }
}
</style>
